import '@babel/polyfill';
import Swiper from 'swiper/dist/js/swiper';
import './app';
import $ from 'jquery';
import 'is-in-viewport';
import {
	TweenMax,
	gsap
} from 'gsap';
import ScrollMagic from "scrollmagic";
import {
	ScrollMagicPluginGsap
} from "scrollmagic-plugin-gsap";

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);


document.addEventListener("DOMContentLoaded", function (event) {
	var swiperGallery = document.querySelector(".foto-gallery");

	var mySwiper1 = new Swiper(swiperGallery, {
		// autoplay: {
		// 	delay: 3000
		// },
		slidesPerView: 1,
		loop: true,
		pagination: {
			el: '.gallery .swiper-pagination',
			clickable: true,
			type: 'bullets',
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}

	});


	$('a[href^="#"]').on('click', function (event) {
		var target = $(this.getAttribute('href'));
		if (target.length) {
			event.preventDefault();
			$('html, body').stop().animate({
				scrollTop: target.offset().top
			}, 1000);

			location.hash = $(this).attr('href');
		}
	});


	var mobileBurger = $('.hero__nav-burger'),
		mobileMenu = $('.hero__nav-list-mobile'),
		body = $('html, body');
	mobileBurger.click(function (e) {
		e.preventDefault();
		if (!$(this).hasClass('hero__nav-burger--active')) {
			// console.log('klik');
			!$(this).addClass('hero__nav-burger--active');
			!$(this).removeClass('hero__nav-burger--not-active');
			mobileMenu.addClass('hero__nav-list-mobile--visible');
			body.addClass('mobile-view');
		} else {
			!$(this).removeClass('hero__nav-burger--active');
			!$(this).addClass('hero__nav-burger--not-active');
			mobileMenu.removeClass('hero__nav-list-mobile--visible');
			body.removeClass('mobile-view');
		}
	});

	const navBurger = document.querySelector('.hero__nav-burger--not-active')

	window.addEventListener('scroll', function (e) {
		const lastPosition = window.scrollY;
		// const hero = document.querySelector('.hero');
		// console.log(hero);
		// const heroHeight = hero.offsetWidth;
		if (lastPosition > 550) {
			navBurger.classList.add('on-scroll')
		} else if (navBurger.classList.contains('on-scroll')) {
			navBurger.classList.remove('on-scroll')

		} else {
			navBurger.classList.remove('on-scroll')
		}
	})

	$('a[data-link]').on('click', function (e) {
		e.preventDefault();
		//console.log(this);
		var target = $(this).attr('data-link');
		console.log(target);
		$('html, body').animate({
			'scrollTop': $('.' + target).offset().top - 50
		}, 700, 'swing');
		$('.hero__nav-list-mobile').toggleClass('hero__nav-list-mobile--visible');
		$('.hero__nav-burger ').toggleClass('hero__nav-burger--active');
		body.toggleClass('mobile-view');
	});




	$('.hero').addClass('active');

	var initPhotoSwipeFromDOM = function (gallerySelector) {

		// parse slide data (url, title, size ...) from DOM elements 
		// (children of gallerySelector)
		var parseThumbnailElements = function (el) {
			var thumbElements = el.childNodes,
				numNodes = thumbElements.length,
				items = [],
				figureEl,
				linkEl,
				size,
				item;

			for (var i = 0; i < numNodes; i++) {

				figureEl = thumbElements[i]; // <figure> element

				// include only element nodes 
				if (figureEl.nodeType !== 1) {
					continue;
				}

				linkEl = figureEl.children[0]; // <a> element

				size = linkEl.getAttribute('data-size').split('x');

				// create slide object
				item = {
					src: linkEl.getAttribute('href'),
					w: parseInt(size[0], 10),
					h: parseInt(size[1], 10)
				};



				if (figureEl.children.length > 1) {
					// <figcaption> content
					item.title = figureEl.children[1].innerHTML;
				}

				if (linkEl.children.length > 0) {
					// <img> thumbnail element, retrieving thumbnail url
					item.msrc = linkEl.children[0].getAttribute('src');
				}

				item.el = figureEl; // save link to element for getThumbBoundsFn
				items.push(item);
			}

			return items;
		};

		// find nearest parent element
		var closest = function closest(el, fn) {
			return el && (fn(el) ? el : closest(el.parentNode, fn));
		};

		// triggers when user clicks on thumbnail
		var onThumbnailsClick = function (e) {
			e = e || window.event;
			e.preventDefault ? e.preventDefault() : e.returnValue = false;

			var eTarget = e.target || e.srcElement;

			// find root element of slide
			var clickedListItem = closest(eTarget, function (el) {
				return (el.tagName && el.tagName.toUpperCase() === 'FIGURE');
			});

			if (!clickedListItem) {
				return;
			}

			// find index of clicked item by looping through all child nodes
			// alternatively, you may define index via data- attribute
			var clickedGallery = clickedListItem.parentNode,
				childNodes = clickedListItem.parentNode.childNodes,
				numChildNodes = childNodes.length,
				nodeIndex = 0,
				index;

			for (var i = 0; i < numChildNodes; i++) {
				if (childNodes[i].nodeType !== 1) {
					continue;
				}

				if (childNodes[i] === clickedListItem) {
					index = nodeIndex;
					break;
				}
				nodeIndex++;
			}



			if (index >= 0) {
				// open PhotoSwipe if valid index found
				openPhotoSwipe(index, clickedGallery);
			}
			return false;
		};

		// parse picture index and gallery index from URL (#&pid=1&gid=2)
		var photoswipeParseHash = function () {
			var hash = window.location.hash.substring(1),
				params = {};

			if (hash.length < 5) {
				return params;
			}

			var vars = hash.split('&');
			for (var i = 0; i < vars.length; i++) {
				if (!vars[i]) {
					continue;
				}
				var pair = vars[i].split('=');
				if (pair.length < 2) {
					continue;
				}
				params[pair[0]] = pair[1];
			}

			if (params.gid) {
				params.gid = parseInt(params.gid, 10);
			}

			return params;
		};

		var openPhotoSwipe = function (index, galleryElement, disableAnimation, fromURL) {
			var pswpElement = document.querySelectorAll('.pswp')[0],
				gallery,
				options,
				items;

			items = parseThumbnailElements(galleryElement);

			// define options (if needed)
			options = {

				// define gallery index (for URL)
				galleryUID: galleryElement.getAttribute('data-pswp-uid'),

				getThumbBoundsFn: function (index) {
					// See Options -> getThumbBoundsFn section of documentation for more info
					var thumbnail = items[index].el.getElementsByTagName('img')[0], // find thumbnail
						pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
						rect = thumbnail.getBoundingClientRect();

					return {
						x: rect.left,
						y: rect.top + pageYScroll,
						w: rect.width
					};
				}

			};

			// PhotoSwipe opened from URL
			if (fromURL) {
				if (options.galleryPIDs) {
					// parse real index when custom PIDs are used 
					// http://photoswipe.com/documentation/faq.html#custom-pid-in-url
					for (var j = 0; j < items.length; j++) {
						if (items[j].pid == index) {
							options.index = j;
							break;
						}
					}
				} else {
					// in URL indexes start from 1
					options.index = parseInt(index, 10) - 1;
				}
			} else {
				options.index = parseInt(index, 10);
			}

			// exit if index not found
			if (isNaN(options.index)) {
				return;
			}

			if (disableAnimation) {
				options.showAnimationDuration = 0;
			}

			// Pass data to PhotoSwipe and initialize it
			gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
			gallery.init();
		};

		// loop through all gallery elements and bind events
		var galleryElements = document.querySelectorAll(gallerySelector);

		for (var i = 0, l = galleryElements.length; i < l; i++) {
			galleryElements[i].setAttribute('data-pswp-uid', i + 1);
			galleryElements[i].onclick = onThumbnailsClick;
		}

		// Parse URL and open gallery if it contains #&pid=3&gid=1
		var hashData = photoswipeParseHash();
		if (hashData.pid && hashData.gid) {
			openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
		}
	};

	// execute above function
	initPhotoSwipeFromDOM('.my-gallery');

	//offer


	$('.offer__item').click(function (e) {
		e.preventDefault();
		if ($(this).hasClass('is-collapsed')) {
			$(this).removeClass('is-collapsed').removeClass('is-inactive').addClass('is-expanded').siblings().removeClass('is-expanded').addClass('is-collapsed').addClass('is-inactive');
		} else if ($(this).hasClass('is-expanded')) {
			$(this).removeClass('is-expanded').addClass('is-collapsed').siblings().removeClass('is-inactive');
		}
	});

	TweenMax.staggerFrom('.hero__img-wrapper', 0.5, {
		opacity: 0,
		delay: 0.5
	}, 0.3);


});
